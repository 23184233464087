import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import PageHeader from "components/PageHeader"
import {
  Desc,
  About,
  Convenience,
  Apartments,
  Location,
  Image,
} from "page_components/investment"

import { parseGqlData } from "utils/parseGqlData"

const Investment = ({ location, data }) => {
  const pageData = parseGqlData({
    data: data?.allWpPage?.nodes,
    queryKey: "Investment",
    single: true,
  })

  return (
    <Layout
      location={location}
      seo={{
        title: pageData?.seo?.title ?? pageData.title,
        description: pageData?.seo?.metaDesc,
      }}
    >
      <PageHeader title={pageData.title} />
      <Desc {...pageData?.intro} />
      <About {...pageData?.about} />
      <Convenience {...pageData?.convenients} />
      <Apartments {...pageData?.apartments} />
      <Location {...pageData?.location} />
      <Image {...pageData?.banner} />
    </Layout>
  )
}

export const query = graphql`
  {
    allWpPage(filter: { id: { eq: "cG9zdDoxMzA3" } }) {
      nodes {
        title
        acfInvestment {
          investmentIntroDescription
          investmentIntroIcons {
            icon {
              localFile {
                publicURL
              }
            }
            label
          }
          investmentAboutContent {
            title
            description
          }
          investmentAboutBlock {
            label {
              number
              postscript
            }
            description
          }
          investmentConvenientsTitle
          investmentConvenientsList {
            icon {
              localFile {
                publicURL
              }
            }
            description
          }
          investmentApartmentsContent {
            title
            description
            columns {
              label
              description
              size
            }
          }
          investmentApartmentsImage {
            localFile {
              publicURL
            }
          }
          investmentLocationTitle
          investmentLocationSubtitle
          investmentLocationDescription
          investmentBannerImage {
            localFile {
              publicURL
            }
          }
        }
        seo {
          title
          metaDesc
        }
      }
    }
  }
`

export default Investment
